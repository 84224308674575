import { BgOverlay, BgBlurred } from "../groups/BgEffects";
import { BiAlarm } from "react-icons/bi";
import { useEffect, useState } from "react";


const ClassesPrices = ({offsetY}) => {
  const offsetRate = 0.1;

  return (
    <div className='classesPrices'>
   
      <BgOverlay bgColor={"#FFF"} bgOpacity="0.8" />
     

      <div className="priceCards">

        <div className="priceCards__card">
          <div className="priceCards__card__header">
            <BiAlarm className="priceCards__card__icon"/>
            <h3 className="priceCards__card__title">60 minutes</h3>
          </div>

          <div className="priceCards__card__body">
            <p className="priceCards__card__content"><span className="priceCards__card__span">17 EUR</span></p>
          </div>
        </div>


        <div className="priceCards__card">
          <div className="priceCards__card__header">
            <BiAlarm className="priceCards__card__icon"/>
            <h3 className="priceCards__card__title">90 minutes</h3>
          </div>
        
          <div className="priceCards__card__body">
            <p className="priceCards__card__content"><span className="priceCards__card__span">24 EUR</span></p>
          </div>
        </div>


        <div className="priceCards__card priceCards__card--wide">
          <div className="priceCards__card__header">
            <h3 className="priceCards__card__title">Advance payment discounts</h3>
          </div>
        
          <div className="priceCards__card__body">
            <p className="priceCards__card__content">4 classes - 90 minutes: <span className="priceCards__card__span">88 EUR</span></p>
            <p className="priceCards__card__content">8 classes - 90 minutes: <span className="priceCards__card__span">176 EUR</span></p>
          </div>
        </div>

      </div>



      <h3 className="classesPrices__payment">Payment via Wise or Revolut</h3>
      <h5 className="classesPrices__policy"><strong>Cancellation policy: </strong>If you cancel the class within 24 hours you will still be charged for the class.</h5>
    </div>
  )
}


export default ClassesPrices
